import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const SearchComponent = ({ activeSearch }) => {
   const [searchTerm, setSearchTerm] = useState('');
   const navigate = useNavigate();

   const handleSubmit = (event) => {
      event.preventDefault();
      navigate(`/search?q=${searchTerm}`);
   };

   const handleClear = () => {
      setSearchTerm('');
   };

   const isTablet = useMediaQuery({ query: '(max-width: 1199px)' });

   return (
      !isTablet ? (
         <div className={`header_search ${activeSearch ? 'active' : ''}`}>
            <form onSubmit={handleSubmit}>
               <div className="header_search_it">
                  <input type="search" placeholder="Найти" value={searchTerm}
                     onChange={(e) => setSearchTerm(e.target.value)} />
                  <button type='button' aria-label='Удалить текст' className="header_search_it-remove" onClick={handleClear}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
                        <rect x="0.857422" y="1.63599" width="1" height="19" transform="rotate(-45 0.857422 1.63599)" fill="#121212" />
                        <rect x="1.56445" y="15.071" width="1" height="19" transform="rotate(-135 1.56445 15.071)" fill="#121212" />
                     </svg>
                  </button>
               </div>
            </form>
         </div>
      ) : (
         <div className="search_mobile">
            <form onSubmit={handleSubmit} className="search_mobile_form">
               <input type="search" placeholder="Поиск" value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} />
               <button type='submit'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                     <path d="M12.3738 12.3876L17 17M14.3333 7.66667C14.3333 11.3485 11.3485 14.3333 7.66667 14.3333C3.98476 14.3333 1 11.3485 1 7.66667C1 3.98476 3.98476 1 7.66667 1C11.3485 1 14.3333 3.98476 14.3333 7.66667Z" stroke="#003374" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
               </button>
            </form>
         </div>
      )
   );
};

export default SearchComponent;