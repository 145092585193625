import React, { useEffect } from 'react'
import './App.scss';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Article from './pages/Article';
import FrontPage from './pages/FrontPage';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider, useQuery, gql } from '@apollo/client';
import Category from './pages/Category';
import Author from './pages/Author';
import TagPage from './pages/TagPage';
import SearchPage from './pages/SearchPage';

const client = new ApolloClient({
  uri: 'https://aspec.gliderdev.ru/graphql',
  cache: new InMemoryCache(),
});

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    })
  }, [pathname]);

  return null;
}

function App() {


  return (
    <ApolloProvider client={client}>
      <Router>
        <ScrollToTop />
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<FrontPage />} />
            <Route path="/:slug" element={<Article />} />
            <Route path="/category/:slug" element={<Category />} />
            <Route path="/tag/:slug" element={<TagPage />} />
            <Route path="/author/:slug" element={<Author />} />
            <Route path="/search" element={<SearchPage />} />
          </Routes>
        </main>
        <Footer />
      </Router>
    </ApolloProvider>

  );
}

export default App;
