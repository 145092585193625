// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.article_card_meta {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  margin-top: 20px;
  row-gap: 5px;
}
@media screen and (max-width: 1199px) {
  .article_card_meta {
    margin-top: 16px;
  }
}
.article_card_meta a {
  transition: 0.4s;
}
@media (hover: hover) {
  .article_card_meta a:hover {
    color: #ADADAD;
  }
}
@media (hover: none) {
  .article_card_meta a:active {
    color: #ADADAD;
  }
}
.article_card_meta-item {
  color: #121212;
  font-size: 18px;
  font-size: clamp(1rem, 0.375rem + 0.625vw, 1.125rem);
  font-weight: 400;
  line-height: 26px;
  line-height: clamp(1.375rem, 0.125rem + 1.25vw, 1.625rem);
}
@media screen and (max-width: 1599px) {
  .article_card_meta-item {
    font-size: 16px;
    line-height: 22px;
  }
}
@media screen and (max-width: 1199px) {
  .article_card_meta-item {
    font-size: 12px;
    line-height: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ArticleCard/utilities/ArticleMeta/ArticleMeta.scss","webpack://./src/scss/_media.scss"],"names":[],"mappings":"AAEA;EACG,aAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;AADH;ACKG;EDTH;IAQM,gBAAA;EAAJ;AACF;AAEG;EACG,gBAAA;AAAN;ACSG;EACG;IDPG,cAAA;EACP;AACF;ACUG;EACG;IDbG,cAAA;EAMP;AACF;AAHG;EACG,cAAA;EACA,eAAA;EACA,oDAAA;EAEA,gBAAA;EACA,iBAAA;EACA,yDAAA;AAIN;AC3BG;EDgBA;IAWM,eAAA;IACA,iBAAA;EAIP;AACF;AC3BG;EDUA;IAgBM,eAAA;IACA,iBAAA;EAKP;AACF","sourcesContent":["@import '../../../../scss/media';\r\n\r\n.article_card_meta {\r\n   display: flex;\r\n   flex-wrap: wrap;\r\n   column-gap: 20px;\r\n   margin-top: 20px;\r\n   row-gap: 5px;\r\n\r\n   @include tablet {\r\n      margin-top: 16px;\r\n   }\r\n\r\n   a {\r\n      transition: .4s;\r\n\r\n      @include hover {\r\n         color: #ADADAD;\r\n      }\r\n   }\r\n\r\n   &-item {\r\n      color: #121212;\r\n      font-size: 18px;\r\n      font-size: clamp(1rem, 0.375rem + 0.625vw, 1.125rem);\r\n      // 18px > 16px\r\n      font-weight: 400;\r\n      line-height: 26px;\r\n      line-height: clamp(1.375rem, 0.125rem + 1.25vw, 1.625rem);\r\n      // 26px > 22px\r\n\r\n      @include desktop {\r\n         font-size: 16px;\r\n         line-height: 22px;\r\n      }\r\n\r\n      @include tablet {\r\n         font-size: 12px;\r\n         line-height: 16px;\r\n      }\r\n   }\r\n}","$desktop: 1599px;\r\n$tablet: 1199px;\r\n$mobile: 767px;\r\n\r\n@mixin desktop {\r\n   @media screen and (max-width: $desktop) {\r\n      @content;\r\n   }\r\n}\r\n\r\n@mixin tablet {\r\n   @media screen and (max-width: $tablet) {\r\n      @content;\r\n   }\r\n}\r\n\r\n@mixin mobile {\r\n   @media screen and (max-width: $mobile) {\r\n      @content;\r\n   }\r\n}\r\n\r\n@mixin hover {\r\n   @media (hover: hover) {\r\n      &:hover {\r\n         @content;\r\n      }\r\n   }\r\n\r\n   @media (hover: none) {\r\n      &:active {\r\n         @content;\r\n      }\r\n   }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
