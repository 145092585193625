import { Link } from "react-router-dom";

function ArticleAsideItem({ asideData }) {
   return (
      <aside>
         <Link to={`/${asideData.slug}`} className="image">
            {asideData.featuredImage != null && <img src={asideData.featuredImage.node.sourceUrl} alt="" />}
         </Link>
         <Link to={`/${asideData.slug}`} className="title">
            {asideData.title}
         </Link>
      </aside>
   );
}

export default ArticleAsideItem;