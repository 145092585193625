import { nanoid } from 'nanoid';
import './ArticleTags.scss'

function ArticleTags({ category }) {

   return (
      category &&
      <div className="article_card_tags">
         {
            category.map(categoryItem => (
               <div className="article_card_tags-tag" key={nanoid()}>{categoryItem.name}</div>
            ))
         }
      </div>

   );
}

export default ArticleTags;