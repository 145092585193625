import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { useLocation } from 'react-router-dom';
import '../components/SearchComponent/SearchPage.scss'
import ArticleSmall from '../components/ArticleCard/ArticleSmall/ArticleSmall';
import { nanoid } from 'nanoid';

const SEARCH_POSTS = gql`
  query SearchPosts($search: String!) {
    posts(where: { search: $search }, first: 20) {
    nodes {
      title
      date
      databaseId
      featuredImage {
        node {
          sourceUrl
        }
      }
      slug
      tags {
        nodes {
          databaseId
          name
          slug
        }
      }
      categories {
        nodes {
          databaseId
          name
          slug
        }
      }
    }
    }
  }
`;

const SearchPage = () => {
   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const searchTerm = searchParams.get('q');

   const { loading, error, data } = useQuery(SEARCH_POSTS, {
      variables: { search: searchTerm },
      skip: !searchTerm,
   });

   if (error) return <p>Ошибка: {error.message}</p>;

   return (
      <section className='search_page'>
         <div className="container">
            <div className="search_page_wrap">
               <h1 className='search_page_wrap-title'>Результаты поиска по запросу: {searchTerm}</h1>
               <div className="search_page_wrap_list ">
                  <div className="row">
                     {loading ? (
                        <p>Загрузка...</p>
                     ) : (
                        data?.posts?.nodes && data?.posts?.nodes.length > 0 ? (
                           data?.posts?.nodes.map((post) => (
                              <ArticleSmall smallPost={post} key={nanoid()} />
                           ))
                        ) : (
                           <div className="search_page_wrap_list-nothing">
                              <p>Упс, ничего не нашли</p>
                           </div>
                        )
                     )}
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
};

export default SearchPage;