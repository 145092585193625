import { Link } from 'react-router-dom';
import ArticleMeta from '../utilities/ArticleMeta/ArticleMeta';
import ArticleTags from '../utilities/ArticleTags/ArticleTags';
import '../ArticleBig/ArticleBig.scss'
import parse from 'html-react-parser';
import { useQuery, gql } from '@apollo/client';
import { useEffect } from 'react';

const GET_POST_CENTER = gql`
query GetPostCenter {
     page(id: "/", idType: URI) {
     mainPage {
       bigPostCenter {
         nodes {
           date
           databaseId
           slug
           ... on Post {
             title
             excerpt
             tags {
               nodes {
                  name
                  slug
               }
             }
             categories {
               nodes {
                  name
               }
            }
             featuredImage {
               node {
                 sourceUrl
               }
             }
           }
         }
       }
     }
   }
 }
`;



function PostCenter({ setPostCenterId }) {

   const { loading, error, data } = useQuery(GET_POST_CENTER);

   useEffect(() => {
      if (data) {
         // Массив id постов которые были выведены по выбранной категории
         const postCenterId = data.page.mainPage.bigPostCenter.nodes.map(post => post.databaseId);
         // Передаем список id в родительский компонент
         setPostCenterId(postCenterId);
      }
   }, [data]);

   if (loading) return (
      <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
         <p>Loading...</p>
      </div >
   );
   if (error) return <p>Error : {error.message}</p>;

   const dataPostCenter = data.page.mainPage.bigPostCenter.nodes[0]

   return (
      dataPostCenter &&
      <div className="col-12">
         <article className="article_big">
            <div className="row">
               <div className="col-xl-7 col-md-6">
                  <div className="article_big_image">
                     <Link to={`/${dataPostCenter.slug}`} className="article_big_image-image">
                        {dataPostCenter.featuredImage != null && <img src={dataPostCenter.featuredImage.node.sourceUrl} alt={dataPostCenter.title} />}
                     </Link>
                     {dataPostCenter.categories.nodes.length > 0 &&
                        <ArticleTags category={dataPostCenter.categories.nodes} />
                     }
                  </div>
               </div>
               <div className="offset-xl-1 col-xl-4 col-md-6">
                  <div className="article_big_content">
                     <Link to={`/${dataPostCenter.slug}`} className='article_big_content-title'>
                        <h3 className='article_big_content-title'>
                           {dataPostCenter.title}
                        </h3>
                     </Link>
                     <div className='article_big_content-text'
                     >
                        {parse(dataPostCenter.excerpt)}
                     </div>
                     <ArticleMeta postDate={dataPostCenter.date} postTag={dataPostCenter.tags.nodes} />
                  </div>
               </div>
            </div>
         </article>
      </div>
   );
}

export default PostCenter;