import { Link } from 'react-router-dom';
import ArticleMeta from '../utilities/ArticleMeta/ArticleMeta';
import ArticleTags from '../utilities/ArticleTags/ArticleTags';
import './ArticleBig.scss'
import parse from 'html-react-parser';


function ArticleBig({ dataPostItem }) {

   return (
      <div className="col-12">
         <article className="article_big">
            <div className="row">
               <div className="col-xl-7 col-md-6">
                  <div className="article_big_image">
                     <Link to={`/${dataPostItem.slug}`} className="article_big_image-image">
                        {dataPostItem.featuredImage != null && <img src={dataPostItem.featuredImage.node.sourceUrl} alt={dataPostItem.title} />}
                     </Link>
                     <ArticleTags category={dataPostItem.categories_details} />
                  </div>
               </div>
               <div className="offset-xl-1 col-xl-4 col-md-6">
                  <div className="article_big_content">
                     <Link to={`/${dataPostItem.slug}`} className='article_big_content-title'>
                        <h3 className='article_big_content-title'>
                           {dataPostItem.title}
                        </h3>
                     </Link>
                     <div className='article_big_content-text'
                     >
                        {parse(dataPostItem.excerpt)}
                     </div>
                     <ArticleMeta postDate={dataPostItem.date} />
                  </div>
               </div>
            </div>
         </article>
      </div>
   );
}

export default ArticleBig;