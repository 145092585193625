import { nanoid } from 'nanoid';
import ArticleSmall from '../components/ArticleCard/ArticleSmall/ArticleSmall';
import '../components/AuthorPage/AuthorPage.scss';
import { useQuery, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';

const GET_AUTHOR = gql`
query GetAuthor($slug: ID!) {
   user(idType: SLUG, id: $slug) {
    databaseId
    firstName
    lastName
    description
    position
    avatar(size: 268) {
      url
    }
    posts(first: 99) {
      nodes {
        databaseId
        date
        slug
        title
        featuredImage {
          node {
            sourceUrl
          }
        }
        categories {
          nodes {
            databaseId
            name
            slug
          }
        }
        tags {
          nodes {
            databaseId
            name
            slug
          }
        }
      }
    }
  }
}
`;

function Author() {
   const { slug } = useParams(); // Получаем ID записи из URL

   function getDeclension(number) {
      const cases = [2, 0, 1, 1, 1, 2];
      const titles = ["статья", "статьи", "статей"];

      return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
   }

   const { loading, error, data } = useQuery(GET_AUTHOR, {
      variables: { slug: slug },
   });

   if (loading) return (
      <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
         <p>Loading...</p>
      </div >
   );
   // Если посты не загрузились
   if (error) return <p>Error : {error.message}</p>;
   // Если посты загрузились задаем переменную
   console.log(data);
   const authorData = data.user && data.user !== null && data.user;

   const authorPosts = authorData && authorData !== null && authorData.posts.nodes.length > 0 ? authorData.posts.nodes : [];

   return (
      authorData && authorData !== null &&
      <section className="author_page">
         <div className="container">
            <div className="author_page_wrap">
               <div className="author_page_wrap_head">
                  <div className="row">
                     <div className="col-xl-2">
                        <div className="author_page_wrap_head-avatar">
                           <img src={authorData.avatar.url} alt={authorData.firstName} />
                        </div>
                     </div>
                     <div className="col-xl-7">
                        <div className="author_page_wrap_head_content">
                           <div className="author_page_wrap_head_content_head">
                              <h1 className="author_page_wrap_head_content-name">{authorData.firstName} {authorData.lastName},</h1>
                              <div className="author_page_wrap_head_content-job">
                                 <p>
                                    {authorData.position}
                                 </p>
                              </div>
                           </div>
                           <div className="author_page_wrap_head_content-descr">
                              <p>
                                 {authorData.description}
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="author_page_wrap_content">
                  <div className="author_page_wrap_content-count">
                     {authorPosts.length} {getDeclension(authorPosts.length)}
                  </div>
                  <div className="author_page_wrap_content_list row">
                     {authorPosts.map(post => (
                        <ArticleSmall smallPost={post} key={nanoid()} />
                     ))}
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
}

export default Author;