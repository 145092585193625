import { Link } from "react-router-dom";
import ArticleMeta from "../ArticleCard/utilities/ArticleMeta/ArticleMeta";
import ArticleTags from "../ArticleCard/utilities/ArticleTags/ArticleTags";
import parse from 'html-react-parser';


function ArticlePageCard({ postRecData }) {


   return (
      <article className="article_card">
         <Link to={`/${postRecData.slug}`} className="article_card-image">
            {postRecData.featuredImage != null && <img src={postRecData.featuredImage.node.sourceUrl} alt="" />}
         </Link>
         <div className="article_card_right">
            <ArticleTags category={postRecData.categories.nodes} />
            <div className="article_card_right_content">
               <Link to={`/${postRecData.slug}`} className="article_card_right_content-title">
                  {postRecData.title}
               </Link>
               <div className="article_card_right_content-text">
                  {parse(postRecData.excerpt)}
               </div>
               <ArticleMeta postDate={postRecData.date} postTag={postRecData.tags.nodes} />
            </div>
         </div>
      </article>
   );
}

export default ArticlePageCard;