
import { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive';
import '../utilities/ColumnBlock/ColumnBlock.scss'
import ArticleSmall from '../ArticleSmall/ArticleSmall';
import SwiperInLine from '../utilities/SwiperInLine/SwiperInLine';
import { useQuery, gql } from '@apollo/client';
import { nanoid } from 'nanoid';

const GET_BOT_CATEGORY = gql`
query GetBotCategory {
     page(id: "/", idType: URI) {
     mainPage {
       cetegory_posts_bottom {
         nodes {
           ... on Category {
             name
             databaseId
            posts(first: 8) {
               nodes {
               databaseId
                 date
                 title
                 slug
                  tags {
                     nodes {
                        name
                        slug
                     }
                     }
                     categories {
                     nodes {
                        name
                     }
                  }
                 featuredImage {
                   node {
                     sourceUrl
                   }
                 }
               }
             }
           }
         }
       }
     }
   }
 }
`;

function LastCategory({ setBotCategoryIds, filter }) {
   const isTablet = useMediaQuery({ query: '(max-width: 1199px)' });

   // Запрос
   const { loading, error, data } = useQuery(GET_BOT_CATEGORY);

   useEffect(() => {
      if (data) {
         // Массив id постов которые были выведены по выбранной категории
         const botCategoryIds = dataBotCategoryPosts.map(post => post.databaseId);
         // Передаем список id в родительский компонент
         if (setBotCategoryIds) {
            setBotCategoryIds(botCategoryIds);
         }
      }
   }, [data, setBotCategoryIds]);

   if (loading) return (
      <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
         <p>Loading...</p>
      </div >
   );
   if (error) return <p>Error : {error.message}</p>;

   // Объект
   const dataBotCategory = data.page.mainPage.cetegory_posts_bottom.nodes[0]

   // Все записи категории
   const dataBotCategoryPosts = dataBotCategory.posts.nodes

   // Фильтруем посты если они уже есть в других блоках

   const filteredPosts = filter && dataBotCategoryPosts.filter(post =>
      !filter.includes(post.databaseId)
   )

   return (
      <>
         {
            filteredPosts && filteredPosts !== null && filteredPosts.length > 0 && (
               !isTablet ? (
                  <div className="col-12">
                     <div className="column_block">
                        <div className="column_block_head">
                           <h2 className="column_block_head-title">Новости</h2>
                        </div>
                        <div className="column_block_list row">
                           {
                              filteredPosts.map(post => (
                                 <ArticleSmall botCategoryPost={post} key={nanoid()} />
                              ))
                           }
                        </div>
                     </div>
                  </div>
               ) : (
                  <SwiperInLine titleSection={'Новости'} dataLastPost={filteredPosts} />
               )
            )
         }
         {
            !filteredPosts && dataBotCategoryPosts && dataBotCategoryPosts.length > 0 && (
               !isTablet ? (
                  <div className="col-12">
                     <div className="column_block">
                        <div className="column_block_head">
                           <h2 className="column_block_head-title">Новости</h2>
                        </div>
                        <div className="column_block_list row">
                           {
                              dataBotCategoryPosts.map(post => (
                                 <ArticleSmall botCategoryPost={post} key={nanoid()} />
                              ))
                           }
                        </div>
                     </div>
                  </div>
               ) : (
                  <SwiperInLine titleSection={'Новости'} dataLastPost={dataBotCategoryPosts} />
               )
            )
         }
      </>
   );
}

export default LastCategory;