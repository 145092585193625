import './BtnSmall.scss'

function BtnSmallLink({ children, blank, link }) {
   return (
      <a href={link ? link : '#'} className="btn" target={blank ? '_blank' : '_self'}>
         {children}
      </a>
   );
}

export default BtnSmallLink;