import BtnSmallLink from '../utilities/BtnSmall/BtnSmallLink';
import './BenefitsBlock.scss'
import { useMediaQuery } from 'react-responsive';
import { useQuery, gql } from '@apollo/client';

const GET_BANNER_MAIN = gql`
query GetBannerMain {
    page(id: "/", idType: URI) {
    mainPage {
      bannerMainPage {
        text
        zagolovok
        img {
          node {
            sourceUrl
          }
        }
        link {
          url
        }
      }
    }
  }
 }
`;

function BenefitsBlock() {
   const isTablet = useMediaQuery({ query: '(max-width: 1199px)' });

   const { loading, error, data } = useQuery(GET_BANNER_MAIN);
   if (loading) return (
      <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
         <p>Loading...</p>
      </div >
   );
   if (error) return <p>Error : {error.message}</p>;

   const dataBanner = data.page.mainPage.bannerMainPage
   return (

      <div className="benefit">
         <div className="benefit_wrap">
            <div className="row">
               <div className="col-xl-4 col-md-6">
                  <div className="benefit_wrap_left">
                     <div className="benefit_wrap_left-title" dangerouslySetInnerHTML={{ __html: dataBanner.zagolovok }} />
                     <div className="benefit_wrap_left-text" dangerouslySetInnerHTML={{ __html: dataBanner.text }} />
                     <BtnSmallLink
                        link={dataBanner.link.url}
                        blank={false}
                     >
                        Узнать подробнее
                     </BtnSmallLink>
                  </div>
               </div>
               <div className="col-xl-8 col-md-6">
                  <div className="benefit_wrap_right">
                     <div className="benefit_wrap_right-image">
                        {dataBanner.img != null && <img src={dataBanner.img.node.sourceUrl} alt='' />}
                     </div>
                     <div className="benefit_wrap_right-icon">
                        <svg width="564" height="300" viewBox="0 0 564 300" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M95.8528 0.401467H4.46114L0 11.3435H80.6262C113.518 11.3435 145.092 24.98 165.963 50.0583C166.328 50.5264 176.255 62.3856 191.225 80.2412C93.7403 113.529 61.9689 169.707 61.9689 185.589C61.9689 216.322 124.047 296.534 236.009 296.534C281.843 296.534 309.889 283.985 321.135 277.667L326.665 264.075C316.674 264.701 284.774 269.476 237.552 255.764C195.335 243.519 161.399 210.199 161.892 188.163C162.41 167.993 186.598 143.047 225.858 121.625C277.708 183.635 344.439 263.392 344.439 263.392C364.466 286.901 393.415 300 428.694 300C422.447 297.92 413.058 294.713 397.447 282.866C389.927 277.174 373.593 258.42 362.635 245.6C357.041 239.047 197.408 47.6043 197.165 47.3451C197.101 47.3071 196.999 47.1047 196.851 46.9403C172.619 17.9025 149.565 0.325417 102.067 0.325417C100.051 0.312767 97.9586 0.338218 95.8528 0.401467Z" fill="white" />
                           <path d="M145.682 0.398825C178.4 4.63652 195.195 23.3456 216.092 47.336C217.789 49.2778 388.168 253.529 388.168 253.548C402.364 270.53 415.805 283.313 436.177 292.92C447.832 298.448 460.64 299.403 473.114 299.611C473.543 299.611 482.158 299.998 482.587 299.998C475.374 298.543 458.662 290.383 449.529 282.408C442.424 276.216 425.706 258.424 414.761 245.597C409.123 239.044 249.515 47.6017 249.272 47.3424C249.208 47.3045 249.125 47.102 248.958 46.9376C223.286 17.7291 203.125 0.000236377 158.182 0.000236377C154.226 -0.00608854 150.066 0.114204 145.682 0.398825Z" fill="white" />
                           <path d="M198.012 0.400931C227.979 4.55008 247.295 23.3477 268.212 47.3381C269.908 49.2799 433.745 245.549 433.745 245.549C447.936 262.55 467.624 283.315 488.994 292.504C500.816 297.533 513.226 299.405 525.24 299.608C525.63 299.608 560.673 299.817 561.108 299.88L564.001 292.594H549.459C522.084 292.594 502.57 284.314 466.881 245.593C462.682 241.058 379.861 141.656 333.054 85.3762C376.155 77.0528 402.435 76.0155 403.344 76.0343C405.34 71.0438 405.949 68.6214 406.781 67.0718C406.781 67.0718 368.225 58.5902 311.735 59.7413C305.264 51.9742 301.437 47.376 301.417 47.3318C301.347 47.2938 301.219 47.1104 301.071 46.927C276.212 14.4232 248.044 0.249023 205.91 0.249023C203.312 0.255348 200.707 0.299733 198.012 0.400931Z" fill="white" />
                        </svg>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

   );
}

export default BenefitsBlock;