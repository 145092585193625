import { useState } from 'react';
import { useLocation } from 'react-router-dom';

function ShareBtn() {

   const [isCopied, setIsCopied] = useState(false);
   const location = useLocation();

   const copyClipboard = (e) => {
      const thisTarget = e.target
      const thisBtn = thisTarget.closest('.article_page_wrap_body_left_share-btn')

      const currentUrl = window.location.origin + location.pathname;

      if (navigator.clipboard && typeof navigator.clipboard.writeText === 'function') {
         navigator.clipboard.writeText(currentUrl)
            .then(() => {
               setIsCopied(true)
               setTimeout(() => {
                  setIsCopied(false)
               }, 5000);
            })
            .catch((error) => {
               console.error('Ошибка при копировании ссылки:', error);
               alert('Не удалось скопировать ссылку');
            });
      } else {
         console.warn('Clipboard API не поддерживается в этом браузере. Используем устаревший метод.');

         const textArea = document.createElement("textarea");
         textArea.value = currentUrl;
         document.body.appendChild(textArea);
         textArea.select();

         try {
            const successful = document.execCommand('copy');
            if (successful) {
               setIsCopied(true)
               setTimeout(() => {
                  setIsCopied(false)
               }, 5000);
            } else {
               console.error('Не удалось скопировать ссылку.');
               alert('Не удалось скопировать ссылку');
            }
         } catch (error) {
            console.error('Ошибка при копировании ссылки:', error);
            alert('Не удалось скопировать ссылку');
         } finally {
            document.body.removeChild(textArea);
         }
      }
   };

   return (
      <>
         <div className="article_page_wrap_body_left_share" onClick={copyClipboard}>
            <button type="button" aria-label="Поделиться" className="article_page_wrap_body_left_share-btn">
               {!isCopied ? (
                  <>
                     <span>Поделиться статьей</span>
                     <div className="icon">
                        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path fillRule="evenodd" clipRule="evenodd" d="M0 10.4667C0 6.80147 2.85801 3.78403 6.59252 3.14924V1.54696C6.59252 0.262052 8.16994 -0.460748 9.25251 0.328118L15.3637 4.78118C16.2121 5.39937 16.2121 6.6006 15.3637 7.21886L9.25258 11.6719C8.16994 12.4607 6.59256 11.738 6.59256 10.453V9.10114C4.93787 9.3987 3.63506 10.197 2.87712 11.2731C2.44087 11.8924 1.68893 12.0209 1.14245 11.8768C0.58947 11.731 8.05757e-05 11.2366 0 10.4667ZM8.35306 1.41153C8.23279 1.32388 8.0575 1.40419 8.0575 1.54696V4.22718C8.0575 4.32193 7.97502 4.3985 7.87393 4.40105C5.19416 4.46849 2.91372 6.00788 1.95255 8.17242C1.80791 8.49812 1.69315 8.83796 1.6114 9.18912C1.52035 9.58022 1.47024 9.98525 1.4654 10.4003C1.46514 10.4224 1.46501 10.4446 1.46501 10.4667C1.46502 10.5593 1.59993 10.5944 1.65434 10.5171C1.66621 10.5003 1.67818 10.4835 1.69024 10.4667C1.92976 10.1343 2.20528 9.82693 2.51245 9.54659C2.72117 9.35616 2.94449 9.17828 3.1811 9.0135C4.43812 8.13824 6.06986 7.63487 7.87415 7.60289C7.97531 7.60111 8.05757 7.67811 8.05757 7.77288V10.453C8.05757 10.5958 8.23286 10.6761 8.35314 10.5885L14.4643 6.13539C14.5586 6.06676 14.5586 5.93328 14.4643 5.86459L8.35306 1.41153Z" fill="#121212" />
                        </svg>
                     </div>
                  </>
               ) : (
                  <>
                     <span>Ссылка скопирована</span>
                     <div className="icon copied">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <g clip-path="url(#clip0_2024_59)">
                              <path d="M12.9998 6.45999V3.94C12.9998 1.84 12.1598 1 10.0598 1H7.53985C5.43985 1 4.59985 1.84 4.59985 3.94V4.6H6.45985C8.55985 4.6 9.39985 5.44 9.39985 7.53999V9.39999H10.0598C12.1598 9.39999 12.9998 8.55999 12.9998 6.45999Z" stroke="#121212" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M9.39999 10.0601V7.54009C9.39999 5.4401 8.55999 4.6001 6.45999 4.6001H3.93999C1.84 4.6001 1 5.4401 1 7.54009V10.0601C1 12.1601 1.84 13.0001 3.93999 13.0001H6.45999C8.55999 13.0001 9.39999 12.1601 9.39999 10.0601Z" stroke="#121212" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M3.44824 8.80086L4.61824 9.97086L6.95225 7.63086" stroke="#121212" stroke-linecap="round" stroke-linejoin="round" />
                           </g>
                           <defs>
                              <clipPath id="clip0_2024_59">
                                 <rect width="14" height="14" fill="white" />
                              </clipPath>
                           </defs>
                        </svg>
                     </div>
                  </>
               )
               }
            </button>
         </div >
      </>
   );
}

export default ShareBtn;