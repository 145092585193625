import { Link } from 'react-router-dom';
import ArticleMeta from '../utilities/ArticleMeta/ArticleMeta';
import ArticleTags from '../utilities/ArticleTags/ArticleTags';
import './ArticleSmall.scss'

function ArticleSmall({ topCategoryPost, botCategoryPost, smallPost }) {
   return (
      <>
         {
            topCategoryPost &&
            <div className="col-md-6">
               <article className="article_small">
                  <div className="article_small_image">
                     <Link to={`/${topCategoryPost.slug}`} className="article_small_image-image">
                        {topCategoryPost.featuredImage != null && <img src={topCategoryPost.featuredImage.node.sourceUrl} alt={topCategoryPost.title} />}
                     </Link>
                  </div>
                  <div className="article_small_content">
                     {topCategoryPost.categories.nodes.length > 0 &&
                        <ArticleTags category={topCategoryPost.categories.nodes} />
                     }
                     <Link to={`/${topCategoryPost.slug}`} className='article_small_content-title'>
                        <h3>
                           {topCategoryPost.title}
                        </h3>
                     </Link>
                     <ArticleMeta postDate={topCategoryPost.date} postTag={topCategoryPost.tags.nodes} />
                  </div>
               </article>
            </div>
         }
         {
            botCategoryPost &&
            <div className="col-md-6">
               <article className="article_small">
                  <div className="article_small_image">
                     <Link to={`/${botCategoryPost.slug}`} className="article_small_image-image">
                        {botCategoryPost.featuredImage != null && <img src={botCategoryPost.featuredImage.node.sourceUrl} alt={botCategoryPost.title} />}
                     </Link>
                  </div>
                  <div className="article_small_content">
                     {botCategoryPost.categories.nodes.length > 0 &&
                        <ArticleTags category={botCategoryPost.categories.nodes} />
                     }
                     <Link to={`/${botCategoryPost.slug}`} className='article_small_content-title'>
                        <h3>
                           {botCategoryPost.title}
                        </h3>
                     </Link>
                     <ArticleMeta postDate={botCategoryPost.date} postTag={botCategoryPost.tags.nodes} />
                  </div>
               </article>
            </div>
         }
         {
            smallPost &&
            <div className="col-md-6">
               <article className="article_small">
                  <div className="article_small_image">
                     <Link to={`/${smallPost?.slug}`} className="article_small_image-image">
                        {smallPost?.featuredImage != null && <img src={smallPost?.featuredImage?.node?.sourceUrl} alt={smallPost?.title} />}
                     </Link>
                  </div>
                  <div className="article_small_content">
                     {smallPost?.categories.nodes.length > 0 &&
                        <ArticleTags category={smallPost?.categories?.nodes} />
                     }
                     <Link to={`/${smallPost?.slug}`} className='article_small_content-title'>
                        <h3>
                           {smallPost?.title}
                        </h3>
                     </Link>
                     <ArticleMeta postDate={smallPost?.date} postTag={smallPost?.tags?.nodes} />
                  </div>
               </article>
            </div>
         }
      </>



   );
}

export default ArticleSmall;