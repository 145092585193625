
import { nanoid } from 'nanoid';
import { EditDate } from '../../../utilities/EditDate';
import './ArticleMeta.scss'
import { Link } from 'react-router-dom';

function ArticleMeta({ postDate, postTag }) {
   return (
      <div className="article_card_meta">
         {
            postTag && postTag !== null && postTag.length > 0 &&
            <Link to={`/tag/${postTag[0].slug}`} className="article_card_meta-item">
               {
                  postTag.map((tag, index) => (
                     index === 0 &&
                     tag.name
                  ))
               }
            </Link>
         }
         <div className="article_card_meta-item">
            {EditDate(postDate)}
         </div>
      </div>
   );
}

export default ArticleMeta;