import ArticleSmall from '../../ArticleSmall/ArticleSmall';
import { useMediaQuery } from 'react-responsive';
import './ColumnBlock.scss'
import SwiperInLine from '../SwiperInLine/SwiperInLine';

function ColumnBlock() {
   const isTablet = useMediaQuery({ query: '(max-width: 1199px)' });

   return (
      !isTablet ? (
         <div className="col-12">
            <div className="column_block">
               <div className="column_block_head">
                  <h2 className="column_block_head-title">Новости</h2>
               </div>
               <div className="column_block_list row">
                  <ArticleSmall />
                  <ArticleSmall />
                  <ArticleSmall />
                  <ArticleSmall />
               </div>
            </div>
         </div>
      ) : (
         <SwiperInLine titleSection={'Новости'} />
      )
   );
}

export default ColumnBlock;