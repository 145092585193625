import React, { useEffect, useRef, useState } from 'react';
import ArticleMeta from "../components/ArticleCard/utilities/ArticleMeta/ArticleMeta";
import ArticleTags from "../components/ArticleCard/utilities/ArticleTags/ArticleTags";
import ColumnBlock from "../components/ArticleCard/utilities/ColumnBlock/ColumnBlock";
import BenefitsBlock from "../components/BenefitsBlock/BenefitsBlock";
import '../components/ArticlePage/ArticalePage.scss';
import { transliterate as tr } from 'transliteration';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from 'swiper/react';
import FontFaceObserver from 'fontfaceobserver';
import { Link, useParams } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { EditDate } from '../components/utilities/EditDate';
import { nanoid } from 'nanoid';
import parse from 'html-react-parser';
import ArticleSmall from '../components/ArticleCard/ArticleSmall/ArticleSmall';
import BtnSmallLink from '../components/utilities/BtnSmall/BtnSmallLink';
import ArticlePageCard from '../components/ArticlePage/ArticlePageCard';
import ArticleAsideItem from '../components/ArticlePage/ArticleAsideItem';
import ShareBtn from '../components/utilities/ShareBtn/ShareBtn';
import LastCategory from '../components/ArticleCard/LastCategory/LastCategory';

gsap.registerPlugin(ScrollTrigger);

const GET_POST = gql`
  query GetPost($slug: ID!) {
    post(idType: SLUG, id: $slug) {
      databaseId
      date
      modified
      readingTime
      tags {
         nodes {
            name
            slug
         }
      }
      categories {
         nodes {
            name
         }
      }
      featuredImage {
            node {
            sourceUrl
         }
      }
      slug
      title
      excerpt
      content
      author {
         node {
            name
            slug
            avatar {
               url
            }
            username
            firstName
            lastName
            description
            position
         }
      }
      postsItemTest {
         genartor_recomend {
            postItem {
               nodes {
                  databaseId
                  date
                  slug
                  ... on Post {
                     id
                     excerpt
                     title
                     featuredImage {
                        node {
                           sourceUrl
                        }
                     }
                     categories {
                        nodes {
                           name
                        }
                     }
                     tags {
                        nodes {
                           name
                           slug
                        }
                     }
                  }
               }
            }
         }
         genartor_aside {
            postItem {
               nodes {
                  databaseId
                  date
                  slug
                  ... on Post {
                     id
                     excerpt
                     title
                     featuredImage {
                        node {
                           sourceUrl
                        }
                     }
                     categories {
                        nodes {
                           name
                        }
                     }
                  }
               }
            }
         }
      }
    }
  }
`;

function Article() {
   const { slug } = useParams(); // Получаем ID записи из URL

   // Получение данных поста
   const { loading, error, data } = useQuery(GET_POST, {
      variables: { slug: slug },
   });

   const [contains, setContains] = useState([]);
   const swiperRef = useRef(null);
   const articleRef = useRef(null)
   const content = useRef(null);
   const links = useRef(null);
   const scrollRef = useRef(null);
   const isTablet = useMediaQuery({ query: '(max-width: 1199px)' });
   const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

   // Якорные ссылки и их обработка
   const handleClick = (e) => {
      e.preventDefault(); // Предотвращаем стандартное поведение ссылки

      // Получаем все ссылки
      const linksAll = links.current.querySelectorAll('a');

      // Удаляем активный класс у ссылки
      linksAll.forEach(link => link.classList.remove('active'));

      // Добавляем активный класс для ссылки по которой кликнули
      e.currentTarget.classList.add('active');

      // Получаем href ссылки
      const href = e.currentTarget.getAttribute('href');

      // Находим элемент с соответствующим ID
      const targetElement = document.querySelector(href);

      if (targetElement) {
         // Выполняем плавный скролл к элементу с отступом сверху
         targetElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
         });
      }
   };

   // Обновление свайпера при загрузке шрифта
   useEffect(() => {
      const font = new FontFaceObserver('NT Somic');

      font.load().then(() => {
         if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.update();
         }
      });
   }, []);

   // Генерация уникальных ID для каждого заголовка
   useEffect(() => {
      if (content.current) {
         const contentAllTitle = content.current.querySelectorAll('h2');
         const arrayAllTitle = Array.from(contentAllTitle);

         // Генерируем уникальные ID для каждого заголовка
         arrayAllTitle.forEach((title, index) => {
            const textContent = title.textContent;
            const transliteratedText = tr(textContent).toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-+|-+$/g, '');
            title.id = transliteratedText;
         });

         setContains(arrayAllTitle);
      }
   }, [data]);

   // Скролл по якорным ссылкам
   useEffect(() => {
      if (!isTablet) {
         if (contains.length > 0) {
            const linksAll = links.current.querySelectorAll('a');

            contains.forEach((title, index) => {
               scrollRef.current = ScrollTrigger.create({
                  trigger: title,
                  start: 'top bottom',
                  end: 'top bottom',
                  onEnter: () => {
                     linksAll.forEach(link => link.classList.remove('active'));
                     linksAll[index].classList.add('active');
                  },

                  onEnterBack: () => {
                     linksAll.forEach(link => link.classList.remove('active'));
                     if (index != 0) {
                        linksAll[index - 1].classList.add('active');
                     }
                  },
               });
            });
         }
      } else {
         if (scrollRef.current) {
            scrollRef.current.kill();
         }
      }

      return () => {
         if (scrollRef.current) {
            scrollRef.current.kill();
         }
      };
   }, [contains, isTablet]);

   // Пока посты загружаются
   if (loading) return (
      <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
         <p>Loading...</p>
      </div >
   );
   // Если посты не загрузились
   if (error) return <p>Error : {error.message}</p>;
   // Если посты загрузились задаем переменную
   const postData = data.post;

   // Вывод поста
   return (
      <article className="article_page" ref={articleRef}>
         <div className="container">
            <div className="article_page_wrap">
               <div className="article_page_wrap_head row">
                  {!isTablet &&
                     <div className="col-xl-3">
                        <div className="article_page_wrap_head_left">
                           <Link to='/' className="article_page_wrap_head_left-back">
                              <div className="icon">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="none">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M6.43434 0.434082L0.434338 6.43408L-0.131348 6.99977L0.434338 7.56545L6.43434 13.5655L7.56571 12.4341L2.93139 7.79977H13V6.19977H2.93139L7.56571 1.56545L6.43434 0.434082Z" fill="#121212" />
                                 </svg>
                              </div>
                              <span>Назад</span>
                           </Link>
                        </div>
                     </div>
                  }
                  <div className="col-xl-6">
                     <div className="article_page_wrap_head_right">
                        <div className="head_content">
                           <div className="head_content_image">
                              <div className="head_content_image-image">
                                 {postData.featuredImage != null && <img src={postData.featuredImage.node.sourceUrl} alt="" />}
                              </div>
                              {postData.categories.nodes.length > 0 &&
                                 <ArticleTags category={postData.categories.nodes} />
                              }
                           </div>
                           {!isMobile ? (
                              <div className="head_content_meta">
                                 {postData.tags.nodes.length > 0 && (
                                    postData.tags.nodes.map((tag, index) => (
                                       index === 0 &&
                                       <div className="head_content_meta-item" key={nanoid()}>
                                          {tag.name}
                                       </div>
                                    ))
                                 )}
                                 <div className="head_content_meta-item">
                                    {EditDate(postData.date)}
                                 </div>
                                 <div className="head_content_meta-item">
                                    Доработано {EditDate(postData.modified)}
                                 </div>
                                 <div className="head_content_meta-item time">
                                    {postData.readingTime} мин
                                 </div>
                              </div>
                           ) : (
                              <Swiper
                                 spaceBetween={16}
                                 slidesPerView={'auto'}
                                 className='head_content_meta'
                                 speed={600}
                                 ref={swiperRef}
                              >
                                 <SwiperSlide className='head_content_meta-item'>
                                    Дом и уют
                                 </SwiperSlide>
                                 <SwiperSlide className='head_content_meta-item'>
                                    24 сентября 2024
                                 </SwiperSlide>
                                 <SwiperSlide className='head_content_meta-item'>
                                    Доработано 20 октября 2024
                                 </SwiperSlide>
                                 <SwiperSlide className='head_content_meta-item time'>
                                    10 мин
                                 </SwiperSlide>
                              </Swiper>
                           )}
                           <h1 className="head_content-title">
                              {postData.title}
                           </h1>
                           <div className="head_content_author">
                              <div className="head_content_author_it">
                                 <Link to={`/author/${postData.author.node.slug}`} className="head_content_author_it-avatar">
                                    <img src={postData.author.node.avatar.url} alt={`${postData.author.node.firstName} ${postData.author.node.lastName}`} />
                                 </Link>
                                 {!isMobile ? (
                                    <div className="head_content_author_it-info">
                                       <p>{`${postData.author.node.firstName} ${postData.author.node.lastName},`}</p>
                                       <p>{postData.author.node.position}</p>
                                    </div>
                                 ) : (
                                    <div className="head_content_author_it-info">
                                       <p>{postData.author.node.firstName} {postData.author.node.lastName},</p>
                                       <p><Link to={`/author/${postData.author.node.slug}`}>автор статьи</Link> и {postData.author.node.position}</p>
                                    </div>
                                 )}
                              </div>
                              {!isMobile &&
                                 <Link to={`/author/${postData.author.node.slug}`} className="head_content_author-btn">Все статьи автора</Link>
                              }
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="article_page_wrap_body row">
                  <div className="col-xl-3 order-xl-1 order-2">
                     <div className="article_page_wrap_body_left">
                        {!isTablet &&
                           contains.length ? (
                           <div className="article_page_wrap_body_left_contain">
                              <div className="article_page_wrap_body_left_contain-title">Содержание:</div>
                              <ul className="article_page_wrap_body_left_contain_list" ref={links}>
                                 {contains.map((title, index) => (
                                    <li key={nanoid()} className="article_page_wrap_body_left_contain_list-item">
                                       <a href={`#${title.id}`} onClick={handleClick}>
                                          {title.textContent}
                                          <div className="icon">
                                             <div className="icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                   <path d="M0 7H12" stroke="#003374" strokeWidth="2" />
                                                   <path d="M6 13L12 7L6 1" stroke="#003374" strokeWidth="2" />
                                                </svg>
                                             </div>
                                          </div>
                                       </a>
                                    </li>
                                 ))}
                              </ul>
                           </div>
                        ) : (
                           <></>
                        )}

                        <ShareBtn />
                     </div>
                  </div>
                  <div className="col-xl-6 order-xl-2 order-1">
                     <div className="article_page_wrap_body_content">
                        {/* {postData.excerpt &&
                           <div className='article_page_wrap_body_content_except'
                              dangerouslySetInnerHTML={{ __html: postData.excerpt }}
                           />
                        } */}
                        <div className="article_page_wrap_body_content_main" ref={content}>
                           {postData.content &&
                              parse(postData.content, {
                                 replace(domNode) {
                                    if (domNode.attribs && domNode.attribs.id === 'replace_post') {
                                       const thisIndexReplace = domNode.attribs['data-index-post']
                                       if (postData.postsItemTest.genartor_recomend != null) {
                                          const thisPostsData = postData.postsItemTest.genartor_recomend[thisIndexReplace]
                                          const thisPostData = thisPostsData.postItem.nodes[0]
                                          return <ArticlePageCard postRecData={thisPostData} />
                                       }
                                    }
                                    if (domNode.attribs && domNode.attribs.id === 'replace_aside') {
                                       const thisIndexReplace = domNode.attribs['data-index-aside']
                                       if (postData.postsItemTest.genartor_recomend != null) {
                                          const thisAsidesData = postData.postsItemTest.genartor_recomend[thisIndexReplace]
                                          const thisAsideData = thisAsidesData.postItem.nodes[0]
                                          return <ArticleAsideItem asideData={thisAsideData} />
                                       }
                                    }
                                 }
                              })
                           }
                           {postData.tags.nodes.length > 0 && (
                              <div className="article_tags_page">
                                 {postData.tags.nodes.map(tag => (
                                    <Link to={`/tag/${tag.slug}`} key={nanoid()}>
                                       {`#${tag.name} `}
                                    </Link>
                                 ))}
                              </div>
                           )
                           }
                        </div>
                     </div>
                  </div>
               </div>
               <LastCategory />
            </div>
         </div>
      </article>
   );
}

export default Article;