// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 6px 16px 8px;
  cursor: pointer;
  background: #003374;
  color: #FFF;
  font-size: 18px;
  font-size: clamp(1rem, 0.375rem + 0.625vw, 1.125rem);
  font-weight: 500;
  line-height: 24px;
  line-height: clamp(1.375rem, 0.75rem + 0.625vw, 1.5rem);
  transition: 0.4s;
}
@media screen and (max-width: 1599px) {
  .btn {
    font-size: 16px;
    line-height: 22px;
  }
}
@media (hover: hover) {
  .btn:hover {
    background: #044393;
  }
}
@media (hover: none) {
  .btn:active {
    background: #044393;
  }
}
.btn:active {
  background: #002E68;
}
.btn:disabled {
  -webkit-user-select: none;
          user-select: none;
  pointer-events: none;
  opacity: 0.2;
}`, "",{"version":3,"sources":["webpack://./src/components/utilities/BtnSmall/BtnSmall.scss","webpack://./src/scss/_media.scss"],"names":[],"mappings":"AAEA;EACG,oBAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;EAEA,eAAA;EACA,mBAAA;EAEA,WAAA;EACA,eAAA;EACA,oDAAA;EAEA,gBAAA;EACA,iBAAA;EACA,uDAAA;EAGA,gBAAA;AANH;ACTG;EDHH;IAqBM,eAAA;IACA,iBAAA;EALJ;AACF;ACGG;EACG;IDIA,mBAAA;EAJJ;AACF;ACIG;EACG;IDFA,mBAAA;EACJ;AACF;AACG;EACG,mBAAA;AACN;AAEG;EACG,yBAAA;UAAA,iBAAA;EACA,oBAAA;EACA,YAAA;AAAN","sourcesContent":["@import '../../../scss/media';\r\n\r\n.btn {\r\n   display: inline-flex;\r\n   justify-content: center;\r\n   align-items: center;\r\n   padding: 6px 16px 8px;\r\n\r\n   cursor: pointer;\r\n   background: #003374;\r\n\r\n   color: #FFF;\r\n   font-size: 18px;\r\n   font-size: clamp(1rem, 0.375rem + 0.625vw, 1.125rem);\r\n   // 18px > 16px\r\n   font-weight: 500;\r\n   line-height: 24px;\r\n   line-height: clamp(1.375rem, 0.75rem + 0.625vw, 1.5rem);\r\n   // 24px > 22px\r\n\r\n   transition: .4s;\r\n\r\n   @include desktop {\r\n      font-size: 16px;\r\n      line-height: 22px;\r\n   }\r\n\r\n   @include hover {\r\n      background: #044393;\r\n   }\r\n\r\n   &:active {\r\n      background: #002E68;\r\n   }\r\n\r\n   &:disabled {\r\n      user-select: none;\r\n      pointer-events: none;\r\n      opacity: .2;\r\n   }\r\n\r\n}","$desktop: 1599px;\r\n$tablet: 1199px;\r\n$mobile: 767px;\r\n\r\n@mixin desktop {\r\n   @media screen and (max-width: $desktop) {\r\n      @content;\r\n   }\r\n}\r\n\r\n@mixin tablet {\r\n   @media screen and (max-width: $tablet) {\r\n      @content;\r\n   }\r\n}\r\n\r\n@mixin mobile {\r\n   @media screen and (max-width: $mobile) {\r\n      @content;\r\n   }\r\n}\r\n\r\n@mixin hover {\r\n   @media (hover: hover) {\r\n      &:hover {\r\n         @content;\r\n      }\r\n   }\r\n\r\n   @media (hover: none) {\r\n      &:active {\r\n         @content;\r\n      }\r\n   }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
