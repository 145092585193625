import { Link } from 'react-router-dom';
import ArticleMeta from '../utilities/ArticleMeta/ArticleMeta';
import ArticleTags from '../utilities/ArticleTags/ArticleTags';
import './ArticleMiddle.scss'

function ArticleMiddle({ dataPostItem }) {


   return (
      <div className="col-md-6">
         <article className="article_middle">
            <div className="article_middle_image">
               <Link to={`/${dataPostItem.slug}`} className="article_middle_image-image">
                  {dataPostItem.featuredImage != null && <img src={dataPostItem.featuredImage.node.sourceUrl} alt={dataPostItem.title} />}
               </Link>
               {dataPostItem.categories.nodes.length > 0 &&
                  <ArticleTags category={dataPostItem.categories.nodes} />
               }
            </div>
            <div className="article_middle_content">
               <Link to={`/${dataPostItem.slug}`} className='article_middle_content-title'>
                  <h3 className='article_middle_content-title'>
                     {dataPostItem.title}
                  </h3>
               </Link>
               <ArticleMeta postDate={dataPostItem.date} postTag={dataPostItem.tags.nodes} />
            </div>
         </article>
      </div>
   );
}

export default ArticleMiddle;