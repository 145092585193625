import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { useEffect, useState, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import SearchComponent from '../SearchComponent/SearchComponent';

const GET_TOP_MENU = gql`
query GetTopMenu {
  menus(where: {slug: "verhnee-menyu"}) {
    nodes {
      databaseId
      menuItems {
        nodes {
          cssClasses
          label
          uri
          databaseId
        }
      }
    }
  }
}
`;

function HeaderTopMenu({ activeBurger }) {
   const [menuHeight, setMenuHeight] = useState(0);
   const isTablet = useMediaQuery({ query: '(max-width: 1199px)' });
   const menuRef = useRef(null);

   const setHeight = () => {
      if (menuRef && menuRef.current) {
         setMenuHeight(menuRef.current.scrollHeight);
      }
   }

   useEffect(() => {
      setHeight()
   }, [activeBurger]);
   // Получение данных верхнего меню
   const { loading, error, data } = useQuery(GET_TOP_MENU, {});

   if (loading) return <p>Loading...</p>;
   // Если посты не загрузились
   if (error) return <p>Error : {error.message}</p>;
   // Если посты загрузились задаем переменную
   const dataTopMenu = data.menus.nodes[0].menuItems;

   return (
      <nav
         className={`header_wrap_nav ${activeBurger ? 'active' : ''}`}
         ref={menuRef}
         style={{ height: activeBurger ? `${menuHeight + 20}px` : '0px' }}>
         {isTablet &&
            <SearchComponent />
         }
         <ul>
            {dataTopMenu.nodes.map(menuItem => (
               <li key={menuItem.databaseId}>
                  <Link to={menuItem.uri}>
                     {menuItem.label}
                  </Link>
               </li>
            ))}
         </ul>
         {isTablet &&
            <div className="social_head">
               <a href='#' className="social_head-item" target='_blank' rel='noffolow noreferrer'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                     <rect width="48" height="48" fill="#EEEFF4" />
                     <path d="M20.0994 25.2964C18.6907 26.1841 17.8857 26.0854 16.6782 25.7896C15.4708 25.4937 14.5652 25.1978 13.3577 24.8033C12.1502 24.4087 11.7477 23.5211 13.8608 22.732C25.0634 17.9321 31.5703 15.2691 33.3815 14.743C35.2934 14.1513 35.7965 14.9403 35.5952 16.4197C35.1257 20.3649 34.3207 25.3293 33.1803 31.3128C32.8784 32.9895 32.0735 34.3703 29.5579 32.6936L23.3193 28.5512C22.3802 27.8937 22.2125 27.2361 22.8162 26.5786C24.1243 25.0991 26.4386 23.3238 29.5579 20.1677C29.8933 19.8389 29.9939 19.5759 29.8598 19.3786C29.7256 19.1814 29.4573 19.2142 29.0548 19.4773" fill="#003374" />
                  </svg>
               </a>
               <a href='#' className="social_head-item" target='_blank' rel='noffolow noreferrer'>
                  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <rect width="48" height="48" fill="#EEEFF4" />
                     <g clipPath="url(#clip0_708_936)">
                        <path d="M24.7941 28.8174C19.8719 28.8174 16.8872 25.405 16.7737 19.7323H19.2697C19.3483 23.8952 21.2421 25.6668 22.6995 26.0246V19.7323H25.0908V23.3279C26.4959 23.1708 27.9621 21.5388 28.4508 19.7323H30.7985C30.4232 21.949 28.8261 23.5897 27.7003 24.2617C28.8261 24.8028 30.6501 26.2341 31.3483 28.8174H28.765C28.2239 27.0894 26.8799 25.7541 25.0908 25.5708V28.8174H24.7941ZM23.5548 34.909H24.4625C29.3934 34.909 31.8632 34.909 33.3905 33.3817C34.909 31.8457 34.909 29.3759 34.909 24.4625V23.5374C34.909 18.6152 34.909 16.1541 33.3905 14.6181C31.8632 13.0908 29.3934 13.0908 24.4625 13.0908H23.5548C18.6239 13.0908 16.1541 13.0908 14.6181 14.6181C13.0821 16.1454 13.0908 18.6239 13.0908 23.5374V24.4625C13.0908 29.3846 13.0908 31.8457 14.6181 33.3817C16.1454 34.9177 18.6239 34.909 23.5548 34.909Z" fill="#003374" />
                     </g>
                     <defs>
                        <clipPath id="clip0_708_936">
                           <rect width="21.8182" height="21.8182" fill="white" transform="translate(13.0908 13.0908)" />
                        </clipPath>
                     </defs>
                  </svg>

               </a>
               <a href='#' className="social_head-item" target='_blank' rel='noffolow noreferrer'>
                  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <rect width="48" height="48" fill="#EEEFF4" />
                     <g clipPath="url(#clip0_708_939)">
                        <mask id="mask0_708_939" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="13" y="13" width="22" height="22">
                           <path d="M29.3545 13.437H18.3913C15.5233 13.437 13.1982 15.762 13.1982 18.6301V29.5933C13.1982 32.4613 15.5233 34.7864 18.3913 34.7864H29.3545C32.2226 34.7864 34.5476 32.4613 34.5476 29.5933V18.6301C34.5476 15.762 32.2226 13.437 29.3545 13.437Z" fill="white" />
                        </mask>
                        <g mask="url(#mask0_708_939)">
                           <path d="M26.3856 23.6243H20.0782V21.1285H26.3856C26.7541 21.1285 27.0102 21.1927 27.1388 21.3048C27.2673 21.417 27.347 21.625 27.347 21.9288V22.825C27.347 23.1451 27.2673 23.3531 27.1388 23.4652C27.0102 23.5774 26.7541 23.6253 26.3856 23.6253V23.6243ZM26.8183 18.7754H17.4033V29.4491H20.0782V25.9765H25.0078L27.347 29.4491H30.3424L27.7634 25.9602C28.7141 25.8193 29.1411 25.5279 29.4932 25.0477C29.8453 24.5675 30.0219 23.7997 30.0219 22.7752V21.9748C30.0219 21.3671 29.9576 20.8869 29.8453 20.5189C29.7331 20.1508 29.5412 19.8307 29.2687 19.5431C28.9809 19.2709 28.6604 19.0792 28.2757 18.9508C27.891 18.8386 27.4103 18.7744 26.8183 18.7744V18.7754Z" fill="#003374" />
                           <path d="M45.2234 13.4369C45.2234 19.3323 40.4441 24.1116 34.5487 24.1116C33.3501 24.1116 32.1976 23.914 31.122 23.5497C31.1451 23.296 31.1552 23.0367 31.1552 22.775V21.9746C31.1552 21.295 31.0842 20.696 30.9294 20.1884C30.7625 19.6409 30.476 19.1698 30.0917 18.7642L30.0704 18.7417L30.0478 18.7203C29.6382 18.333 29.174 18.0565 28.6354 17.8767L28.6147 17.8698L28.5936 17.8636C28.0809 17.7142 27.4889 17.642 26.8195 17.642H25.6873V17.643H24.7346C24.1807 16.3523 23.874 14.9304 23.874 13.4369C23.874 7.54141 28.6533 2.76221 34.5487 2.76221C40.4441 2.76221 45.2234 7.54141 45.2234 13.4369Z" fill="#003374" />
                        </g>
                        <path d="M29.354 13.5986H18.3908C15.6123 13.5986 13.3599 15.8511 13.3599 18.6296V29.5927C13.3599 32.3712 15.6123 34.6237 18.3908 34.6237H29.354C32.1325 34.6237 34.3849 32.3712 34.3849 29.5927V18.6296C34.3849 15.8511 32.1325 13.5986 29.354 13.5986Z" stroke="#003374" strokeWidth="0.371567" />
                     </g>
                     <defs>
                        <clipPath id="clip0_708_939">
                           <rect width="21.8182" height="21.8182" fill="white" transform="translate(13.0908 13.0908)" />
                        </clipPath>
                     </defs>
                  </svg>

               </a>
            </div>
         }
      </nav>
   );
}

export default HeaderTopMenu;
