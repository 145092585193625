import { Link } from 'react-router-dom';

function HeaderLogo() {

   return (
      <Link to='/' className='header_wrap-logo icon' aria-label='Логотип'>
         <svg width="258" height="40" viewBox="0 0 258 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M158.482 7.92773V17.0311H158.577L166.379 7.92773H171.594L162.587 17.5752L172.144 29.2353H166.592L158.577 18.64H158.482V29.2353H154.105V7.92773H158.482Z" fill="#28377A" />
            <path d="M181.57 7.92773H177.194V29.2353H181.57V7.92773Z" fill="#36B4A9" />
            <path d="M82.7082 7.92773L91.2844 29.2353H86.3466L84.5333 24.4262H75.6204L73.8012 29.2353H69.1174L77.7231 7.92773H82.7082ZM83.2339 20.9217L80.0562 12.2162H79.9971L77.0025 20.9217H83.2339Z" fill="#28377A" />
            <path d="M108.579 28.5621C106.665 29.2057 104.722 29.4748 102.997 29.4748C97.628 29.4748 91.7688 26.4618 91.7688 18.6981C91.7688 11.3616 97.0137 7.68164 103.794 7.68164C105.489 7.68164 106.942 7.89226 108.573 8.41295L108.39 12.0695C107.126 11.5839 105.738 11.2797 104.101 11.2797C99.2641 11.2797 96.3936 14.2927 96.3936 18.6455C96.3936 23.7881 99.902 25.8943 103.794 25.8943C105.708 25.8943 107.22 25.4379 108.295 24.9523L108.579 28.5621Z" fill="#28377A" />
            <path d="M129.883 7.92773V29.2353H125.506V11.4263H116.966V29.2353H112.589V7.92773H129.883Z" fill="#28377A" />
            <path d="M137.148 16.5103H146.214C145.878 14.4392 144.46 11.1864 139.026 11.1864C137.491 11.1864 135.884 11.4613 134.372 12.0113L134.035 8.60042C135.695 8.05047 137.674 7.68774 139.983 7.68774C146.457 7.68774 150.839 11.6485 150.839 18.2538C150.839 25.0404 146.675 29.4868 139.339 29.4868C137.455 29.4868 135.577 29.1825 133.569 28.4805L134.094 24.8883C136.374 25.8595 138.347 25.9823 139.487 25.9823C143.929 25.9823 146.149 23.3086 146.208 20.0148H137.142L137.148 16.5103Z" fill="#28377A" />
            <path d="M12.7875 0.0585643H0.596553L0 1.51535H10.7616C15.1501 1.51535 19.3673 3.33486 22.1492 6.6755C22.1965 6.73986 23.5254 8.3195 25.5218 10.7007C12.5099 15.1412 8.27495 22.6298 8.27495 24.7477C8.27495 28.8431 16.5617 39.5378 31.4991 39.5378C37.6182 39.5378 41.357 37.8646 42.8573 37.0221L43.5956 35.2084C42.2607 35.2904 38.0081 35.9281 31.7059 34.1027C26.0711 32.4704 21.5409 28.0299 21.6058 25.0929C21.6767 22.4017 24.9016 19.0786 30.1407 16.2235C37.0571 24.4903 45.964 35.1207 45.964 35.1207C48.6338 38.2566 52.5025 40 57.2099 40C56.3771 39.725 55.125 39.298 53.04 37.7183C52.0359 36.9578 49.8564 34.4596 48.3916 32.7512C47.6474 31.8795 26.3428 6.35371 26.3074 6.31861C26.3015 6.31276 26.2837 6.28936 26.266 6.26596C23.0293 2.39292 19.9579 0.052717 13.6144 0.052717C13.3486 0.0468665 13.071 0.0527138 12.7875 0.0585643Z" fill="url(#paint0_radial_266_787)" />
            <path d="M19.438 0.0584998C23.8029 0.626 26.0473 3.11832 28.8352 6.31855C29.0596 6.57597 51.7995 33.8043 51.7995 33.8101C53.6954 36.0743 55.4851 37.7768 58.208 39.058C59.7614 39.7952 61.4742 39.9239 63.1399 39.9473C63.1989 39.9473 64.3448 40 64.4038 40C63.4411 39.8069 61.2085 38.7187 59.9917 37.6539C59.0467 36.829 56.814 34.4595 55.3492 32.7453C54.5991 31.8736 33.2945 6.3478 33.265 6.3127C33.2591 6.30685 33.2473 6.28345 33.2237 6.26005C29.7979 2.36361 27.1046 0 21.1095 0C20.5779 0.00585051 20.0227 0.0233967 19.438 0.0584998Z" fill="url(#paint1_radial_266_787)" />
            <path d="M26.4255 0.0585664C30.4242 0.614365 33.0053 3.11838 35.7932 6.31861C36.0176 6.57604 57.8833 32.7454 57.8833 32.7454C59.7793 35.0095 62.4018 37.7827 65.2546 39.0054C66.8316 39.6782 68.4913 39.924 70.092 39.9532C70.1451 39.9532 74.8231 39.9825 74.8762 39.9883L75.2601 39.0171H73.3169C69.6608 39.0171 67.062 37.9114 62.2955 32.7512C61.7343 32.1486 50.6834 18.8972 44.4343 11.391C50.1872 10.2794 53.6957 10.1449 53.8138 10.1449C54.0796 9.47789 54.1623 9.15611 54.2745 8.95134C54.2745 8.95134 49.13 7.8222 41.5874 7.97432C40.7251 6.93878 40.2112 6.32446 40.2112 6.31861C40.1994 6.31276 40.1876 6.28936 40.164 6.26596C36.8445 1.93073 33.088 0.0410156 27.4651 0.0410156C27.1284 0.0410156 26.7858 0.0468654 26.4255 0.0585664Z" fill="url(#paint2_radial_266_787)" />
            <path d="M187.79 16.9899V14.3396H188.38C188.664 14.3396 188.823 14.129 188.865 13.7078L189.302 7.92163H193.779V14.3338H194.594V16.9841H193.383V15.4103H188.995V16.9841H187.79V16.9899ZM190.389 9.00396L190.028 13.7546C190.028 13.9067 189.981 14.0705 189.892 14.246L189.845 14.3338H192.568V8.99812H190.389V9.00396Z" fill="#36B4A9" />
            <path d="M203.595 10.5368V12.8127C203.595 13.5791 203.312 14.2344 202.751 14.7726C202.184 15.305 201.469 15.5683 200.601 15.5683C199.715 15.5683 198.988 15.305 198.421 14.7726C197.866 14.2344 197.589 13.5732 197.589 12.7951V10.531C197.589 9.75286 197.866 9.10344 198.421 8.58275C198.988 8.0445 199.715 7.77539 200.601 7.77539C201.469 7.77539 202.184 8.0445 202.751 8.58275C203.318 9.12099 203.595 9.77627 203.595 10.5368ZM202.355 12.8887V10.4608C202.355 10.0044 202.196 9.6183 201.871 9.30822C201.546 8.99814 201.127 8.84603 200.601 8.84603C200.075 8.84603 199.65 8.99814 199.313 9.30822C198.977 9.6183 198.817 10.0044 198.817 10.4608V12.8712C198.817 13.3334 198.983 13.7195 199.313 14.0296C199.644 14.3396 200.075 14.5035 200.601 14.5035C201.127 14.5035 201.546 14.3514 201.871 14.0413C202.19 13.7312 202.355 13.3451 202.355 12.8887Z" fill="#36B4A9" />
            <path d="M211.628 9.2029L211.368 9.96933L210.341 12.0053H208.964L207.966 9.98103L207.712 9.2029L207.653 9.22047L207.742 10.4783V15.422H206.561V7.92749H208.208L209.632 11.1219H209.691L211.114 7.92749H212.78V15.422H211.599V10.4783L211.687 9.22047L211.628 9.2029Z" fill="#36B4A9" />
            <path d="M218.858 8.8575C218.344 8.8575 217.918 9.01546 217.588 9.33139C217.257 9.64732 217.092 10.0335 217.092 10.4957V12.8768C217.092 13.3449 217.257 13.7368 217.588 14.0469C217.918 14.357 218.35 14.5091 218.875 14.5091C219.389 14.5091 219.797 14.3511 220.098 14.0293C220.399 13.7017 220.553 13.3331 220.553 12.9353H221.793C221.793 13.497 221.569 14.0528 221.126 14.6144C220.901 14.9069 220.588 15.1351 220.181 15.3106C219.773 15.4861 219.33 15.5739 218.858 15.5739C217.96 15.5739 217.233 15.2989 216.678 14.749C216.135 14.1873 215.863 13.5379 215.863 12.8008V10.5366C215.863 9.77604 216.141 9.12663 216.696 8.58838C217.263 8.05013 217.983 7.78101 218.858 7.78101C219.36 7.78101 219.809 7.86876 220.198 8.03843C220.588 8.20809 220.889 8.43041 221.096 8.71124C221.309 8.99206 221.462 9.2729 221.563 9.56542C221.663 9.85795 221.716 10.1505 221.716 10.4488H220.476C220.476 10.3494 220.47 10.2616 220.458 10.1914C220.429 10.0042 220.376 9.81699 220.299 9.64732C220.222 9.47181 220.057 9.29629 219.809 9.12078C219.555 8.94526 219.242 8.8575 218.858 8.8575Z" fill="#36B4A9" />
            <path d="M224.499 9.00398V7.92749H231.155V9.00398H228.432V15.4162H227.221V9.00398H224.499Z" fill="#36B4A9" />
            <path d="M235.372 14.9832L235.189 14.7901V18.1191H233.978V7.92749H235.065L235.112 8.54181C235.171 8.4599 235.236 8.38968 235.307 8.33118C235.425 8.20832 235.662 8.0913 236.004 7.96259C236.347 7.83973 236.713 7.77539 237.091 7.77539C237.888 7.77539 238.568 8.0445 239.135 8.58275C239.69 9.10344 239.967 9.75286 239.967 10.531V12.8068C239.967 13.5674 239.684 14.2168 239.123 14.755C238.568 15.2933 237.894 15.5624 237.097 15.5624C236.737 15.5624 236.388 15.4981 236.069 15.3752C235.75 15.2582 235.514 15.1236 235.372 14.9832ZM238.733 12.8536V10.4549C238.733 9.99858 238.568 9.61244 238.243 9.30237C237.918 8.99229 237.493 8.84017 236.979 8.84017C236.465 8.84017 236.04 8.99813 235.703 9.31406C235.366 9.62999 235.195 10.0161 235.195 10.4783V12.8595C235.195 13.3217 235.366 13.7019 235.703 14.012C236.04 14.3221 236.465 14.4742 236.979 14.4742C237.493 14.4742 237.912 14.3221 238.243 14.012C238.573 13.7019 238.733 13.3158 238.733 12.8536Z" fill="#36B4A9" />
            <path d="M248.975 10.5368V12.8127C248.975 13.5791 248.691 14.2344 248.13 14.7726C247.563 15.305 246.848 15.5683 245.98 15.5683C245.094 15.5683 244.368 15.305 243.801 14.7726C243.245 14.2344 242.968 13.5732 242.968 12.7951V10.531C242.968 9.75286 243.245 9.10344 243.801 8.58275C244.368 8.0445 245.094 7.77539 245.98 7.77539C246.848 7.77539 247.563 8.0445 248.13 8.58275C248.697 9.12099 248.975 9.77627 248.975 10.5368ZM247.734 12.8887V10.4608C247.734 10.0044 247.575 9.6183 247.25 9.30822C246.925 8.99814 246.506 8.84603 245.98 8.84603C245.454 8.84603 245.029 8.99814 244.692 9.30822C244.356 9.6183 244.196 10.0044 244.196 10.4608V12.8712C244.196 13.3334 244.362 13.7195 244.692 14.0296C245.023 14.3396 245.454 14.5035 245.98 14.5035C246.506 14.5035 246.925 14.3514 247.25 14.0413C247.569 13.7312 247.734 13.3451 247.734 12.8887Z" fill="#36B4A9" />
            <path d="M256.641 7.92742H258V15.4219H256.807V10.566L256.931 9.2906H256.872L256.553 10.174L253.434 15.4161H252.087V7.92158H253.281V12.7307L253.127 14.0471H253.186L253.546 13.1168L256.641 7.92742ZM253.015 5.14258H254.149C254.149 5.83294 254.385 6.17812 254.858 6.17812H255.253C255.72 6.17812 255.95 5.83294 255.95 5.14258H257.084C257.084 5.72178 256.925 6.18398 256.6 6.52916C256.275 6.87434 255.838 7.044 255.283 7.044H254.816C254.249 7.044 253.812 6.87434 253.493 6.52916C253.168 6.18398 253.015 5.72178 253.015 5.14258Z" fill="#36B4A9" />
            <path d="M193.472 23.01L193.218 23.7764L192.19 25.8124H190.814L189.815 23.7882L189.556 23.01L189.496 23.0276L189.585 24.2855V29.2291H188.404V21.7346H190.052L191.475 24.929H191.534L192.958 21.7346H194.623V29.2291H193.442V24.2855L193.531 23.0276L193.472 23.01Z" fill="#36B4A9" />
            <path d="M202.231 26.9358H203.471C203.483 27.4857 203.241 28.0298 202.745 28.568C202.249 29.1063 201.546 29.3754 200.63 29.3754C199.721 29.3754 198.988 29.1063 198.439 28.568C197.896 28.0181 197.624 27.3628 197.624 26.6081V24.3323C197.624 23.5834 197.896 22.9399 198.439 22.4016C198.994 21.8634 199.727 21.5942 200.63 21.5942C201.54 21.5942 202.255 21.8634 202.78 22.4016C203.312 22.9516 203.584 23.5951 203.584 24.3323V25.8592H198.847V26.6842C198.847 27.1464 199.012 27.5325 199.349 27.8426C199.685 28.1527 200.111 28.3165 200.636 28.3165C201.067 28.3165 201.446 28.1819 201.764 27.9128C202.072 27.6378 202.231 27.3102 202.231 26.9358ZM198.847 24.2796V24.7711H202.385V24.2796C202.385 23.8174 202.225 23.4371 201.9 23.1271C201.575 22.817 201.156 22.6649 200.63 22.6649C200.105 22.6649 199.679 22.8228 199.343 23.1271C199.012 23.4371 198.847 23.8233 198.847 24.2796Z" fill="#36B4A9" />
            <path d="M205.94 30.7973V28.147H206.531C206.815 28.147 206.974 27.9364 207.015 27.5152L207.452 21.729H211.93V28.1412H212.745V30.7915H211.534V29.2177H207.145V30.7915H205.94V30.7973ZM208.539 22.8114L208.179 27.562C208.179 27.7141 208.132 27.8779 208.043 28.0534L207.996 28.1412H210.719V22.8055H208.539V22.8114Z" fill="#36B4A9" />
            <path d="M220.34 21.7349H221.705V29.2294H220.512V24.3734L220.636 23.098H220.577L220.258 23.9815L217.139 29.2235H215.792V21.729H216.986V26.5381L216.832 27.8545H216.891L217.257 26.9243L220.34 21.7349Z" fill="#36B4A9" />
            <path d="M230.641 29.2235H229.46V28.5332C229.43 28.5741 229.354 28.6502 229.235 28.773C229.135 28.8608 228.911 28.9895 228.568 29.1475C228.214 29.2996 227.853 29.3698 227.481 29.3698C226.772 29.3698 226.152 29.1475 225.621 28.7087C225.107 28.2582 224.847 27.7024 224.847 27.0296C224.847 26.3802 225.089 25.8302 225.573 25.3797C226.058 24.9175 226.648 24.666 227.345 24.6133L229.419 24.4788V24.0868C229.419 23.6889 229.283 23.3496 229.011 23.0746C228.739 22.7997 228.379 22.6651 227.936 22.6651C227.523 22.6651 227.174 22.7646 226.891 22.9635C226.607 23.1624 226.442 23.4257 226.389 23.7591H225.195C225.278 23.1273 225.573 22.6124 226.087 22.2029C226.619 21.7817 227.239 21.571 227.93 21.571C228.775 21.571 229.442 21.7934 229.921 22.2439C230.399 22.6943 230.641 23.2794 230.641 23.999V29.2235ZM229.413 26.8716V25.5669L227.434 25.7015C227.038 25.7308 226.713 25.8712 226.448 26.111C226.188 26.3568 226.052 26.6551 226.052 27.0179C226.052 27.3689 226.194 27.6673 226.483 27.9189C226.772 28.1704 227.121 28.2933 227.534 28.2933C228.09 28.2933 228.538 28.1412 228.881 27.8428C229.235 27.5269 229.413 27.2051 229.413 26.8716Z" fill="#36B4A9" />
            <defs>
               <radialGradient id="paint0_radial_266_787" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(67.0527 40.4597) scale(77.2201 76.2298)">
                  <stop stopColor="#263877" />
                  <stop offset="0.2021" stopColor="#263877" />
                  <stop offset="0.3306" stopColor="#215F8C" />
                  <stop offset="0.5363" stopColor="#269DA4" />
                  <stop offset="0.628" stopColor="#35B5AA" />
                  <stop offset="1" stopColor="#35B5AA" />
               </radialGradient>
               <radialGradient id="paint1_radial_266_787" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(67.0532 40.4596) scale(77.2201 76.2298)">
                  <stop stopColor="#263877" />
                  <stop offset="0.2021" stopColor="#263877" />
                  <stop offset="0.3306" stopColor="#215F8C" />
                  <stop offset="0.5363" stopColor="#269DA4" />
                  <stop offset="0.628" stopColor="#35B5AA" />
                  <stop offset="1" stopColor="#35B5AA" />
               </radialGradient>
               <radialGradient id="paint2_radial_266_787" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(67.0534 40.4702) scale(77.2201 76.2302)">
                  <stop stopColor="#263877" />
                  <stop offset="0.2021" stopColor="#263877" />
                  <stop offset="0.3306" stopColor="#215F8C" />
                  <stop offset="0.5363" stopColor="#269DA4" />
                  <stop offset="0.628" stopColor="#35B5AA" />
                  <stop offset="1" stopColor="#35B5AA" />
               </radialGradient>
            </defs>
         </svg>
      </Link>
   );
}

export default HeaderLogo;
