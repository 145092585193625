import { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';
import '../utilities/SwiperInLine/SwiperInLine.scss';
import { nanoid } from 'nanoid';
import ArticleSmall from '../ArticleSmall/ArticleSmall';
import { useQuery, gql } from '@apollo/client';

const GET_TOP_CATEGORY = gql`
query GetTopCategory {
     page(id: "/", idType: URI) {
     mainPage {
       tag_under_hightlight {
         nodes {
           ... on Tag {
             name
             databaseId
            posts(first: 8) {
               nodes {
               databaseId
                 date
                 title
                 slug
                  tags {
                     nodes {
                        name
                        slug
                     }
                  }
                  categories {
                  nodes {
                     name
                  }
                 }
                 featuredImage {
                   node {
                     sourceUrl
                   }
                 }
               }
             }
           }
         }
       }
     }
   }
 }
`;

function FirstCategory({ setTopCategoryIds, filter }) {
   const uniqueId = nanoid(3); // Генерируем уникальный идентификатор длиной 6 символов
   const prevClass = `section_swiper_head_btns-prev-${uniqueId}`;
   const nextClass = `section_swiper_head_btns-next-${uniqueId}`;
   const swiperClass = `section_swiper_content-${uniqueId}`;

   // Запрос
   const { loading, error, data } = useQuery(GET_TOP_CATEGORY);

   useEffect(() => {
      if (data) {
         // Массив id постов которые были выведены по выбранной категории
         const topCategoryIds = dataTopCategoryPosts.map(post => post.databaseId);
         // Передаем список id в родительский компонент
         setTopCategoryIds(topCategoryIds);
      }
   }, [data]);

   if (loading) return (
      <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
         <p>Loading...</p>
      </div >
   );
   if (error) return <p>Error : {error.message}</p>;

   // Объект
   const dataTopCategory = data.page.mainPage.tag_under_hightlight.nodes[0]

   // Все записи категории
   const dataTopCategoryPosts = dataTopCategory.posts.nodes

   // const filteredPosts = dataTopCategoryPosts.filter(post =>
   //    !filter.includes(post.databaseId)
   // )

   return (
      dataTopCategoryPosts.length > 0 &&
      <div className="col-12">
         <div className="section_swiper">
            <div className="section_swiper_head">
               <h2 className='section_swiper_head-title'>{dataTopCategory.name}</h2>
               <div className="section_swiper_head_btns">
                  <div className={`swiper-button-prev section_swiper_head_btns-prev ${prevClass}`}>
                     <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="40" height="40" stroke="#121212" />
                        <path d="M28 21L14 21" stroke="#121212" strokeWidth="2" />
                        <path d="M21 14L14 21L21 28" stroke="#121212" strokeWidth="2" />
                     </svg>
                  </div>
                  <div className={`swiper-button-next section_swiper_head_btns-next ${nextClass}`}>
                     <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                        <rect x="41" y="41" width="40" height="40" transform="rotate(-180 41 41)" stroke="#121212" />
                        <path d="M14 21L28 21" stroke="#121212" strokeWidth="2" />
                        <path d="M21 28L28 21L21 14" stroke="#121212" strokeWidth="2" />
                     </svg>
                  </div>
               </div>
            </div>
            <Swiper
               modules={[Navigation]}
               spaceBetween={40}
               slidesPerView={2}
               className={`section_swiper_content ${swiperClass}`}
               speed={600}
               navigation={{
                  nextEl: `.${nextClass}`,
                  prevEl: `.${prevClass}`,
               }}
               breakpoints={{
                  1: {
                     slidesPerView: 'auto',
                     spaceBetween: 7,
                  },
                  768: {
                     slidesPerView: 2,
                     spaceBetween: 20,
                  },
                  1200: {
                     slidesPerView: 2,
                     spaceBetween: 30,
                  },
                  1600: {
                     slidesPerView: 2,
                     spaceBetween: 40,
                  },
               }}
            >
               {
                  dataTopCategoryPosts.map(post => (
                     <SwiperSlide className='section_swiper_content-item' key={nanoid()}>
                        <ArticleSmall topCategoryPost={post} />
                     </SwiperSlide>
                  ))
               }
            </Swiper>
         </div>
      </div>
   );
}

export default FirstCategory;