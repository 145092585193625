export const EditDate = (postDate) => {
   const date = new Date(postDate);
   const formattedDate = `${date.getDate()} ${getMonthName(date.getMonth())} ${date.getFullYear()}`;

   function getMonthName(month) {
      const monthNames = [
         "января",
         "февраля",
         "марта",
         "апреля",
         "мая",
         "июня",
         "июля",
         "августа",
         "сентября",
         "октября",
         "ноября",
         "декабря"
      ];
      return monthNames[month];
   }

   return formattedDate
}

