import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { Swiper, SwiperSlide } from 'swiper/react';
import FontFaceObserver from 'fontfaceobserver';

const GET_BOT_MENU = gql`
query GetBotMenu {
  menus(where: {slug: "nizhnee-menyu"}) {
    nodes {
      databaseId
      menuItems {
        nodes {
          cssClasses
          label
          uri
          databaseId
        }
      }
    }
  }
}
`;

function HeaderBotMenu() {
   const swiperRef = useRef(null);
   const [swiperState, setSwiperState] = useState({ start: true, end: false });

   // Прогрузка шрифтов для обновления слайдера
   useEffect(() => {
      const font = new FontFaceObserver('NT Somic');

      font.load().then(() => {
         if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.update();
         }
      });
   }, []);

   const handleReachBeginning = () => {
      setSwiperState({ start: true, end: false });
   };

   const handleReachEnd = () => {
      setSwiperState({ start: false, end: true });
   };

   const handleSlideChange = () => {
      if (swiperRef.current && swiperRef.current.swiper) {
         const { isBeginning, isEnd } = swiperRef.current.swiper;
         setSwiperState({ start: isBeginning, end: isEnd });
      }
   };

   // Получение данных нижнего меню
   const { loading, error, data } = useQuery(GET_BOT_MENU, {});

   if (loading) return (
      <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
         <p>Loading...</p>
      </div >
   );
   // Если посты не загрузились
   if (error) return <p>Error : {error.message}</p>;
   // Если посты загрузились задаем переменную
   const dataBotMenu = data.menus.nodes[0].menuItems;

   return (
      <div className="header_wrap_tags">
         <Swiper
            ref={swiperRef}
            spaceBetween={40}
            slidesPerView={'auto'}
            tag='nav'
            wrapperTag='ul'
            className={`sw_tags ${swiperState.start ? 'start' : ''} ${swiperState.end ? 'end' : ''}`}
            breakpoints={{
               1: {
                  spaceBetween: 20,
               },
               1200: {
                  spaceBetween: 30,
               },
               1600: {
                  spaceBetween: 40,
               },
            }}
            onReachBeginning={handleReachBeginning}
            onReachEnd={handleReachEnd}
            onSlideChange={handleSlideChange}
         >
            {
               dataBotMenu.nodes.map(menuItem => (
                  <SwiperSlide tag='li' className='sw_tags-item' key={menuItem.databaseId}>
                     <Link to={menuItem.uri}>
                        {menuItem.label}
                     </Link>
                  </SwiperSlide>
               ))
            }
         </Swiper>
      </div>
   );
}

export default HeaderBotMenu;
