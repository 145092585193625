import React, { useState, useMemo } from 'react'

import ArticleBig from "../components/ArticleCard/ArticleBig/ArticleBig";
import ArticleMiddle from "../components/ArticleCard/ArticleMiddle/ArticleMiddle";
import ArticleWide from "../components/ArticleCard/ArticleWide/ArticleWide";
import ColumnBlock from "../components/ArticleCard/utilities/ColumnBlock/ColumnBlock";
import SwiperInLine from "../components/ArticleCard/utilities/SwiperInLine/SwiperInLine";
import BenefitsBlock from "../components/BenefitsBlock/BenefitsBlock";
import { useQuery, gql } from '@apollo/client';
import Article from './Article';
import Highlight from '../components/ArticleCard/Highlight/Highlight';
import FirstCategory from '../components/ArticleCard/FirstCategory/FirstCategory';
import PostCenter from '../components/ArticleCard/PostCenter/PostCenter';
import LastCategory from '../components/ArticleCard/LastCategory/LastCategory';

const GET_POSTS = gql`
query GetPosts {
  posts(first: 10) {
      nodes {
        databaseId
        date
        title
        slug
        excerpt
        tags {
         nodes {
            name
            slug
         }
        }
         categories {
            nodes {
               name
            }
         }
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
  }
}
`;

function FrontPage() {
   const [topCategoryIds, setTopCategoryIds] = useState([]);
   const [botCategoryIds, setBotCategoryIds] = useState([]);
   const [highlightId, setHighlightId] = useState([]);
   const [postCenterId, setPostCenterId] = useState([]);
   const [expertPostId, setExpertPostId] = useState([]);

   const { loading, error, data } = useQuery(GET_POSTS);

   const filterTopCategory = useMemo(() => [...botCategoryIds, ...highlightId, ...postCenterId, ...expertPostId], [botCategoryIds, highlightId, postCenterId, expertPostId]);
   const filterLastCategory = useMemo(() => [...topCategoryIds, ...highlightId, ...postCenterId, ...expertPostId], [topCategoryIds, highlightId, postCenterId, expertPostId]);

   const filteredPosts = useMemo(() => {
      if (!data) return [];
      return data.posts.nodes.filter(post =>
         !topCategoryIds.includes(post.databaseId) &&
         !botCategoryIds.includes(post.databaseId) &&
         !highlightId.includes(post.databaseId) &&
         !postCenterId.includes(post.databaseId) &&
         !expertPostId.includes(post.databaseId)
      );
   }, [data, topCategoryIds, botCategoryIds, highlightId, postCenterId, expertPostId]);

   if (loading) return <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><p>Loading...</p></div>;
   if (error) return <p>Error : {error.message}</p>;

   return (
      <div className="fornt_page">
         <div className="container">
            <div className="fornt_page_wrap articles_list row">
               <Highlight setHighlightId={setHighlightId} />
               {filteredPosts.slice(0, 2).map((post) => (
                  <ArticleMiddle dataPostItem={post} key={post.databaseId} />
               ))}
               <FirstCategory setTopCategoryIds={setTopCategoryIds} filter={filterTopCategory} />
               {filteredPosts.slice(2, 4).map((post) => (
                  <ArticleMiddle dataPostItem={post} key={post.databaseId} />
               ))}
               <PostCenter setPostCenterId={setPostCenterId} />
               {filteredPosts.slice(4, 8).map((post) => (
                  <ArticleMiddle dataPostItem={post} key={post.databaseId} />
               ))}
               <ArticleWide setExpertPostId={setExpertPostId} />
               <BenefitsBlock />
               {filteredPosts.slice(8, 10).map((post) => (
                  <ArticleMiddle dataPostItem={post} key={post.databaseId} />
               ))}
               <LastCategory setBotCategoryIds={setBotCategoryIds} filter={filterLastCategory} />
            </div>
         </div>
      </div>
   );
}

export default FrontPage;