import ArticleMeta from '../utilities/ArticleMeta/ArticleMeta';
import ArticleTags from '../utilities/ArticleTags/ArticleTags';
import { useMediaQuery } from 'react-responsive';
import './ArticleWide.scss'
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import { useEffect } from 'react';

const GET_POST_EXPERT = gql`
query GetPostExpert {
     page(id: "/", idType: URI) {
     mainPage {
       expertBlock {
         nodes {
           date
           databaseId
           slug
           ... on Post {
             title
             excerpt
             author {
               node {
                  name
                  avatar {
                     url
                  }
                  username
                  firstName
                  lastName
                  description
               }
            }
             tags {
               nodes {
                  name
                  slug
               }
             }
             categories {
               nodes {
                  name
               }
            }
             featuredImage {
               node {
                 sourceUrl
               }
             }
           }
         }
       }
     }
   }
 }
`;

function ArticleWide({ setExpertPostId }) {
   const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

   const { loading, error, data } = useQuery(GET_POST_EXPERT);

   useEffect(() => {
      if (data) {
         // Массив id постов которые были выведены по выбранной категории
         const expertPostId = data.page.mainPage.expertBlock.nodes.map(post => post.databaseId);
         // Передаем список id в родительский компонент
         setExpertPostId(expertPostId);
      }
   }, [data]);
   if (loading) return (
      <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
         <p>Loading...</p>
      </div >
   );
   if (error) return <p>Error : {error.message}</p>;

   const dataPostExpert = data.page.mainPage.expertBlock.nodes[0]

   return (
      dataPostExpert &&
      <div className="col-12">
         <article className="article_wide">
            <div className="row">
               <div className="col-md-2">
                  <div className="article_wide_left">
                     {dataPostExpert.categories.nodes.length > 0 &&
                        <ArticleTags category={dataPostExpert.categories.nodes} />
                     }
                     {isMobile && <ArticleMeta postDate={dataPostExpert.date} postTag={dataPostExpert.tags.nodes} />}
                  </div>
               </div>
               <div className="col-xl-4 col-md-5">
                  <div className="article_wide_content">
                     <Link to={`/${dataPostExpert.slug}`} className='article_wide_content-title'>
                        <h3 className='article_wide_content-title'>
                           {dataPostExpert.title}
                        </h3>
                     </Link>
                     {!isMobile && <ArticleMeta postDate={dataPostExpert.date} postTag={dataPostExpert.tags.nodes} />}
                  </div>
               </div>
               <div className="offset-xl-1 col-md-5">
                  <div className="article_wide_author">
                     <div className="article_wide_author-text" dangerouslySetInnerHTML={{ __html: dataPostExpert.excerpt }} />
                     <div className="article_wide_author_item">
                        <div className="article_wide_author_item-avatar">
                           <img src={dataPostExpert.author.node.avatar.url} alt={`${dataPostExpert.author.node.firstName} ${dataPostExpert.author.node.lastName}`} />
                        </div>
                        <div className="article_wide_author_item-info">
                           <p>{`${dataPostExpert.author.node.firstName} ${dataPostExpert.author.node.lastName},`}</p>
                           <p>{dataPostExpert.author.node.description}</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </article>
      </div>
   );
}

export default ArticleWide;