import { nanoid } from 'nanoid';
import ArticleMiddle from '../components/ArticleCard/ArticleMiddle/ArticleMiddle';
import '../components/CategoryPage/CategoryPage.scss'
import { useQuery, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';

const GET_TAGS = gql`
query GetTags($slug: ID!) {
   tag(idType: SLUG, id: $slug) {
    tagId
    name
    description
    slug
    posts(first: 10) {
      nodes {
        date
        excerpt
        databaseId
        title
        slug
         tags {
         nodes {
            name
         }
        }
         categories {
            nodes {
               name
            }
         }
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
}
`;

function TagPage() {
   const { slug } = useParams(); // Получаем ID записи из URL

   // Получение данных поста
   const { loading, error, data } = useQuery(GET_TAGS, {
      variables: { slug: slug },
   });

   if (loading) return (
      <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
         <p>Loading...</p>
      </div >
   );
   // Если посты не загрузились
   if (error) return <p>Error : {error.message}</p>;
   // Если посты загрузились задаем переменную
   const categoryTags = data.tag;

   return (
      <div className="category_page">
         <div className="container">
            <div className="category_page_wrap">
               <div className="category_page_wrap_head">
                  <div className="row">
                     <div className="col-xl-5">
                        <div className="category_page_wrap_head_left">
                           <h1 className="category_page_wrap_head_left-title">{categoryTags.name}</h1>
                           <div className="category_page_wrap_head_left-text">
                              {categoryTags.description && categoryTags.description !== null} {
                                 <p>
                                    {categoryTags.description}
                                 </p>
                              }

                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="category_page_wrap_head-image">
                     <img src="../../img/tag/tag-banner.jpg" alt="Ремонт и дизайн" />
                  </div>
               </div>
               {categoryTags.posts.nodes.length > 0 ? (
                  <div className="category_page_wrap_list row">
                     {categoryTags.posts.nodes.map(post => (
                        <ArticleMiddle dataPostItem={post} key={nanoid()} />
                     ))}
                  </div>
               ) : (
                  <span>Записи не найдены</span>
               )}
            </div>
         </div>
      </div>
   );
}

export default TagPage;