import { useState, useRef } from 'react';
import './Header.scss';
import BtnSmallLink from '../utilities/BtnSmall/BtnSmallLink';
import { useMediaQuery } from 'react-responsive';
import HeaderTopMenu from './HeaderTopMenu';
import HeaderLogo from './HeaderLogo';
import HeaderBotMenu from './HeaderBotMenu';
import SearchComponent from '../SearchComponent/SearchComponent';

function Header() {
   const [activeBurger, setActiveBurger] = useState(false)
   const [activeSearch, setActiveSearch] = useState(false)
   const [activeSearchHeight, setActiveSearchHeight] = useState(0)
   const isShowBurger = useMediaQuery({ query: '(max-width: 1199px)' });
   const isTablet = useMediaQuery({ query: '(max-width: 1199px)' });
   const searchBlockRef = useRef(null)

   const toggleBurger = (e) => {
      e.preventDefault();
      setActiveBurger(!activeBurger);
   };
   // Анимация раскрытыия поиска
   const toggleSearch = (e) => {
      if (!isTablet) {
         if (searchBlockRef && searchBlockRef.current) {
            e.preventDefault();
            setActiveSearch(!activeSearch);

            const searchBlock = searchBlockRef.current
            activeSearch ? setActiveSearchHeight(0) : setActiveSearchHeight(searchBlock.scrollHeight)
         }
      }
   };

   return (
      <header className="header">
         <div className="container">
            <div className="header_wrap">
               <div className="header_wrap_top">
                  <HeaderLogo />
                  {!isTablet && <HeaderTopMenu activeBurger={activeBurger} />}
                  <div className="header_wrap_right">
                     <button className={`header_wrap_right-search icon ${activeSearch ? 'active' : ''}`} onClick={toggleSearch}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                           <path d="M15.2172 15.2346L21 21M17.6667 9.33333C17.6667 13.9357 13.9357 17.6667 9.33333 17.6667C4.73096 17.6667 1 13.9357 1 9.33333C1 4.73096 4.73096 1 9.33333 1C13.9357 1 17.6667 4.73096 17.6667 9.33333Z" stroke="#003374" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                     </button>
                     <BtnSmallLink>
                        Основной сайт
                     </BtnSmallLink>
                  </div>
                  {isShowBurger &&
                     <button type='button' aria-label='Меню' className={`header_wrap_burger ${activeBurger ? 'active' : ''}`} onClick={toggleBurger}>
                        <span></span>
                     </button>
                  }
                  {isTablet && <HeaderTopMenu activeBurger={activeBurger} />}
               </div>
               {!isTablet &&
                  <div className="search_block" ref={searchBlockRef} style={{ height: activeSearchHeight }}>
                     <SearchComponent />
                  </div>
               }
               <HeaderBotMenu />
            </div>
         </div>
      </header >
   );
}

export default Header;
